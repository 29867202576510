// document.addEventListener("DOMContentLoaded", function() {
  // alert('test')

  const particles = document.querySelectorAll('.particle');
  const randomX = random(1, document.body.clientWidth < 1024 ? 20 : 40);
  const randomY = random(1, document.body.clientWidth < 1024 ? 20 : 40);
  const randomDelay = random(0, 1);
  const randomTime = random(1, 10);
  const randomTime2 = random(10, 20);
  const randomAngle = random(0, 0);
  var currentWidth = window.outerWidth;
  console.log(particles);


  // const particles = gsap.utils.toArray('.particle');
  // particles.forEach(particle => {
  //   var pad = 50;
  //   var vw = window.innerWidth;
  //   var vh = window.innerHeight;

  //   particle.placed = true;
  //   particle.left = randomInt(pad, vw - (particle.clientWidth + pad));
  //   particle.top = randomInt(pad, vh - (particle.clientHeight + pad));
  //   particle.right = particle.left + particle.clientWidth;
  //   particle.bottom = particle.top + particle.clientHeight;
  // // console.log(particle.left)

  //   var b = particle;

  //   // Skip if b is this bubble or isn't placed
  //   if (b === particle || !b.placed) {
  //     continue;
  //   }

  //   // Collision detected, particle't place bubble
  //   if (intersects(particle, b)) {
  //     particle.placed = false;
  //     break;
  //   }
  //  if (bubble.placed) {

  //     // No collisions detected. It's place is reserved and we particle animate to it
  //     animateBubble(bubble);

  //   } else {

  //     // particle't place bubble. Try again on next animation frame
  //     requestAnimationFrame(function () {
  //       placeParticle(bubble);
  //     });
  //   }

  //   gsap.set(particle, {
  //     x: particle.left,
  //     y: particle.top,
  //     rotation: randomAngle(-1)
  //   });

  //   // moveX(particle, 1);
  //   // moveY(particle, -1);
  //   // rotate(particle, 1);

  //   if (particle.placed) {

  //     // No collisions detected. It's place is reserved and we particle animate to it
  //     animateBubble(bubble);

  //   } else {

  //     // particle't place bubble. Try again on next animation frame
  //     requestAnimationFrame(function () {
  //       placeParticle(bubble);
  //     });
  //   }


  // });

  function rotate(target, direction) {

    gsap.to(target, randomTime2(), {
      rotation: randomAngle(direction),
      // delay: randomDelay(),
      ease: Sine.easeInOut,
      onComplete: rotate,
      onCompleteParams: [target, direction * -1]
    });
  }

  function moveX(target, direction) {

    gsap.to(target, randomTime(), {
      x: target.left + randomX(direction),
      ease: Sine.easeInOut,
      onComplete: moveX,
      onCompleteParams: [target, direction * -1]
    });
  }

  function moveY(target, direction) {
    gsap.to(target, randomTime(), {
      y: target.top + randomY(direction),
      // scrollTrigger: {
      //   scrub: true
      // },
      // y: (i, target) => (-ScrollTrigger.maxScroll(window) * Math.random()) + target.top + randomY(direction),
      ease: Sine.easeInOut,
      onComplete: moveY,
      onCompleteParams: [target, direction * -1],

    });
  }

  function random(min, max) {
    const delta = max - min;
    return (direction = 1) => (min + delta * Math.random()) * direction;
  }




  // var vw = window.innerWidth;
  // var vh = window.innerHeight;

  // var pad = 6;
  // var minWidth = 70;
  // var maxWidth = 140;
  // var bubbleHeight = 70;

  // var elastic = Elastic.easeOut.config(0.3, 0.3);

  var pad = 20;
  var vw = window.innerWidth;
  var vh = window.innerHeight;
  var vh = document.querySelector('#spaces').clientHeight;


  function resize() {
    vw = window.innerWidth;
    // vh = window.innerHeight;
    vh = document.querySelector('#spaces').clientHeight;
  }

  function particlesInit() {

    for (var i = 0; i < particles.length; i++) {
      placeParticle(particles[i]);
    }
  }
  particlesInit();

  window.addEventListener("resize", function () {

    if ( window.innerWidth !== currentWidth ) {
      resize();
      particlesInit();
    }
    // resize();
    // particlesInit();
  });

  function placeParticle(particle) {
    // console.log(vw, vh)
    particle.placed = true;
    particle.width = particle.clientWidth;
    particle.height = particle.clientHeight;
    // particle.width = randomInt(minWidth, maxWidth);
    particle.left = randomInt(pad, vw - (particle.width + pad));
    particle.top = randomInt(pad, vh - (particle.height + pad));
    particle.right = particle.left + particle.width;
    particle.bottom = particle.top + particle.height;

    // console.log(particle.top)
    // gsap.set(particle, {
    //   x: particle.left,
    //   y: particle.top,
    //   // rotation: randomAngle(-1)
    // });

    // Loop through all particles
    for (var i = 0; i < particles.length; i++) {

      var b = particles[i];

      // Skip if b is this particle or isn't placed
      if (b === particle || !b.placed) {
        continue;
      }

      // Collision detected, particle't place particle
      if (intersects(particle, b)) {
        particle.placed = false;
        break;
      }

    }

    if (particle.placed) {
      particle.classList.add('fade')
      // No collisions detected. It's place is reserved and we particle animate to it
      animateparticle(particle);

    } else {

      // particle't place particle. Try again on next animation frame
      requestAnimationFrame(function () {
        placeParticle(particle);
      });
    }
  }

  function animateparticle(particle) {

    gsap.set(particle, {
      x: particle.left - randomX(-1),
      y: particle.top - randomX(1),
      rotation: randomAngle(-1),
    });
    moveX(particle, 1);
    moveY(particle, -1);
    rotate(particle, 1);


  }

  // function createBubble(index) {

  //   var element = document.createElement("div");
  //   document.body.appendChild(element);
  //   element.className = "bubble";
  //   element.style.height = bubbleHeight + "px";
  //   element.textContent = "#" + (index + 1);

  //   return {
  //     element: element,
  //     placed: false,
  //     width: minWidth,
  //     height: bubbleHeight,
  //     left: 0,
  //     top: 0,
  //     right: minWidth,
  //     bottom: bubbleHeight
  //   };
  // }

  function intersects(a, b) {
    return !(b.left > a.right + pad ||
      b.right < a.left - pad ||
      b.top > a.bottom + pad ||
      b.bottom < a.top - pad);
  }

  // function random(min, max) {
  //   if (max == null) { max = min; min = 0; }
  //   if (min > max) { var tmp = min; min = max; max = tmp; }
  //   return min + (max - min) * Math.random();
  // }

  function randomInt(min, max) {
    if (max == null) {
      max = min;
      min = 0;
    }
    if (min > max) {
      var tmp = min;
      min = max;
      max = tmp;
    }
    return Math.floor(min + (max - min + 1) * Math.random());
  }


  window.addEventListener('load', function(){

    resize();
      particlesInit();
  
  });

  // document.addEventListener('scroll', function(e) {
  //   gsap.killTweensOf(".particle");
  //   gsap.to('.particle', {
  //     scrollTrigger: {
  //       scrub: true
  //     },
  //     y: (i, target) => -Math.random() * target.top ,
  //     ease: "none"
  //   });
  // });
// });